import React from "react";
import "./pages.css";

const Projects = () => {

  return (
    <>
    <div className="our-team-background"></div>
      <div
        style={{ width: "100%", marginBottom: "auto", overflowX: "hidden" }}
      >
      </div>
      <div
        style={{
          marginTop: "40px",
          marginBottom: "60px",
          alignItems: "center",
          display: "flex",
          justifyContent: "space-bewteen",
          flexDirection: "column",
        }}
      >
    
      </div>
  </>
  );
};

export default Projects;
