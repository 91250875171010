import React from 'react';
import HeaderTypingAnimation from '../components/HeaderTypingAnimation';


function Home() {
  return (
    <>
      <HeaderTypingAnimation/>
    </>
  );
}

export default Home;
